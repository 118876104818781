import React from 'react';
import queryString from 'query-string';
import styled from 'styled-components';
import { Link } from 'gatsby';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import ContentWrapper from '../../components/layout/ContentWrapper';

import ContactForm from '../../components/forms/ContactForm';

import { ViewportBreakpoints } from '../../components/cssConstants';

import H1 from '@rotaready/frecl/build/H1';
import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import Button from '@rotaready/frecl/build/Button';
import Text from '@rotaready/frecl/build/Text';
import UL from '@rotaready/frecl/build/UL';
import TextListItem from '@rotaready/frecl/build/TextListItem';

import ClientLogoBar, { ClientLogos } from '../../components/section/ClientLogoBar';
import Blockquote from '../../components/frills/Blockquote';

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 60px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-bottom: 100px;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 1 1 50%;
    margin: 0;
  }
`;

const Section = styled.div`
  margin: 0 0 40px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding-right: 120px;
  }
`;

const Heading = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const TextBlock = styled.div`
  margin-top: 20px;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const LinkSection = styled.div`
  margin: 0 0 40px 0;
`;

const LinkPadding = styled.div`
  margin-top: 20px;
`;

const header = {
  backgroundColor: 'white',
  invertColors: false,
};

class DemoPage extends React.Component {
  render({ location }) {
    const {
      given_name: givenName,
      family_name: familyName,
      email,
    } = location.search ? queryString.parse(location.search) : {};

    return (
      <Body header={header}>
        <SEO
          title="Brochure - Rotaready"
          description="Download a Rotaready brochure. Seriously good rota software, built for hospitality businesses."
          url="brochure"
        />

        <ResponsiveContainer>
          <ContentWrapper>
            <TitleWrapper>
              <H1 uistyle="brand160" text="Download brochure" />
            </TitleWrapper>

            <BodyWrapper>
              <BodyItem>
                <Section>
                  <Heading text="Seriously good rota software, built for hospitality businesses" />

                  <TextBlock>
                    <Text size="lg">
                      Whether you’re a single or multi-site operator, Rotaready takes the stress out of staff scheduling and enables you to build rotas that are perfectly aligned with demand. Effortlessly schedule rotas, optimise wage spend, record attendance and approve timesheets for <Link style="color:inherit;" to="/payroll">payroll</Link>.
                    </Text>
                  </TextBlock>
                </Section>

                <Section>
                  <Heading text="Download the brochure to learn more about Rotaready’s extensive feature set, including:" />

                  <TextBlock>
                    <UL size="lg" uistyle="normal" iconUistyle="primary">
                      <TextListItem text="Rota scheduling" />
                      <TextListItem text="HR" />
                      <TextListItem text="Time & attendance" />
                      <TextListItem text="Demand forecasting" />
                      <TextListItem text="Real-time integrations" />
                    </UL>
                  </TextBlock>
                </Section>

                <Section>
                  <Blockquote
                    text="Rotaready is the perfect balance between intelligence and simplicity. This combined with the transparency they provide over their development pipeline gave us great confidence it was a scalable solution that would be able to grow with us. They were the obvious choice when it came to selecting our workforce management partner. "
                  />
                </Section>

                <Section>
                  <Heading text="Working with 1,500+ venues across the UK" />

                  <LogoWrapper>
                    <ClientLogoBar
                      logos={[
                        ClientLogos.BREWDOG,
                        ClientLogos.PIZZA_PILGRIMS,
                        ClientLogos.HICKORYS_SMOKEHOUSE,
                        ClientLogos.GOLDEN_LION_GROUP
                      ]}
                      withWrapper={false}
                    />
                  </LogoWrapper>
                </Section>

              </BodyItem>

              <BodyItem>
                <ContactForm
                  title=""
                  commentsLabel="Any specific questions or requirements?"
                  submitLabel="Download brochure"
                  showComments
                  showPhone
                  source="brochure"
                  marketoProgramName="FY25-P05-HOS-CDP-BOF-Brochure-Rotaready-com-Brochure-Rotaready"
                  label="rotaready.com/brochure"
                  initialFirstName={givenName}
                  initialLastName={familyName}
                  initialEmail={email}
                  onSuccess={(
                    <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Thank you!" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for requesting a copy of our brochure.  Just click the link below to view it." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/Brochure_Rotaready_HOS_230525.pdf" download>
                        <Button uistyle="primary" text="Show me the brochure" size="md" />
                      </a>
                    </TextBlock>
                  </>
                  )}
                />
              </BodyItem>
            </BodyWrapper>
          </ContentWrapper>
        </ResponsiveContainer>
      </Body>
    );
  }
}

export default DemoPage;
